// src/components/Vision.js
import React from 'react';
import '../styles/Vision.css';
import visionImage1 from '../assets/vision-image1.png';
import visionImage2 from '../assets/vision-image2.png';
import visionImage3 from '../assets/vision-image3.png';
import visionImage4 from '../assets/vision-image4.png';
import visionImage5 from '../assets/vision-image5.png';
import visionImage6 from '../assets/vision-image6.png';

const Vision = () => {
  return (
    <section id="vision" className="vision-section">
      <h3 className="vision-title">Vision</h3>
      <h2>Designing Better lives with AI</h2>
      <p>Creating knowledge for everyone, empowering more people to experience better learning in everyday life.</p>
      <div className="vision-cards">
        <div className="card">
          <img src={visionImage1} alt="Providing Equal Learning Opportunities for All" />
          <p>Providing Equal Learning<br/>Opportunities for All</p>
        </div>
        <div className="card">
          <img src={visionImage2} alt="Helping Navigate the Flood of Information to Find What Matters" />
          <p>Helping Navigate the Flood of<br/>Information to Find What Matters</p>
        </div>
        <div className="card">
          <img src={visionImage3} alt="Enhancing Memory with Personalized Reminders" />
          <p>Enhancing Memory with Personalized<br/>Reminders</p>
        </div>
        <div className="card">
          <img src={visionImage4} alt="Supporting Lifelong Learning and Personal Growth" />
          <p>Supporting Lifelong Learning and<br/>Personal Growth</p>
        </div>
        <div className="card">
          <img src={visionImage5} alt="Creating Sustainable Learning Environments" />
          <p>Creating Sustainable Learning<br/>Environments</p>
        </div>
        <div className="card">
          <img src={visionImage6} alt="Providing Mentorship and Guidance for Personal Development" />
          <p>Providing Mentorship and Guidance<br/> for Personal Development</p>
        </div>
      </div>
    </section>
  );
};

export default Vision;
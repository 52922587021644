import React from 'react';
import '../styles/Team.css';

const Team = () => {
  return (
    <section id="team" className="team-section">
      <div className="team-container">
        <div className="team-left">
          <h3 className="team-title">The G.P.C Crew</h3>
          <h2>We are a united crew for a<br/>better world</h2>
          <p>Joining forces to bring knowledge and learning to the world. Together,<br/>we create a brighter tomorrow.</p>
        </div>
        <div className="team-right">
          <div className="team-member">
            <div className="member-icon">😁</div>
            <div className="member-info">
              <h4>Jason</h4>
              <p>Value Architect</p>
            </div>
          </div>
          <div className="team-member">
            <div className="member-icon">😼</div>
            <div className="member-info">
              <h4>Kong</h4>
              <p>Value Designer</p>
            </div>
          </div>
          <div className="team-member">
            <div className="member-icon">😉</div>
            <div className="member-info">
              <h4>Won</h4>
              <p>Value Engineer</p>
            </div>
          </div>
          <div className="team-member">
            <div className="member-icon">🙄</div>
            <div className="member-info">
              <h4>Vincent</h4>
              <p>Value Engineer</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
import React from 'react';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Vision from './components/Vision';
import PathToFuture from './components/PathToFuture';
import Service from './components/Service';
import Team from './components/Team';
import JoinUs from './components/JoinUs';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <Vision />
      <PathToFuture />
      <Service />
      <Team />
      <JoinUs />
      <Footer />
    </div>
  );
}

export default App;

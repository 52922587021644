import React from 'react';
import '../styles/HeroSection.css';

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-background"></div> {/* 배경 이미지용 div */}
      <div className="hero-content">
        <h1>A New Way to Grow <br></br>
          Knowledge in Everyday Life</h1>
        <p>For every moment you seek to learn, we're here to grow with you. Experience tailored<br></br>
         learning that helps you become a better version of yourself, day by day.</p>
      </div>
    </section>
  );
};

export default HeroSection;
// src/components/Service.js
import React from 'react';
import '../styles/Service.css';
import serviceImage from '../assets/service-image.png';

const Service = () => {
  return (
    <section id="services" className="service-section">
            <div className="service-container">
        <div className="service-left">
          <h3 className="service-title">Service</h3>
          <div className="service-header">
            <h2>Your Partner in<br/>Knowledge and Learning<br/>for Everyone</h2>
            <br/>
            <p>Helping you build knowledge effortlessly in daily life with<br/>personalized learning and memory support.</p>
          </div>
          <div className="service-details">
            <div className="service-item">
              <h3>🔒 AI-Powered Personalized Tutoring</h3>
            </div>
            <div className="service-item-detail">
            <p>All tailored to your learning style, answering questions and helping you<br/>understand essential knowledge effortlessly.</p>
            </div>
            <div className="service-item">
              <h3>🔒 Smart Learning Reminders</h3>
            </div>
            <div className="service-item-detail">
            <p>Consistent reminders for key learning points, keeping you on track<br/>toward your goals.</p>
            </div>
          </div>
        </div>
        <div className="service-right">
          <img src={serviceImage} alt="Service illustration" />
        </div>
      </div>
    </section>
  );
};

export default Service;
// src/components/PathToFuture.js
import React from 'react';
import '../styles/PathToFuture.css';

const PathToFuture = () => {
  return (
    <section id="path-to-future" className="path-to-future">
      <h3>Path to the Future</h3>
      <h2>A Journey of Knowledge, Together</h2>
      <p>We move toward a world where everyone can grow. Join us as we bring G.P.C's vision to life.</p>
      <div className="timeline">
        <div className="timeline-item">
          <h4>● 2025 ─────</h4>
          <h5>Personalized Learning<br/>Solutions for K-13 Students</h5>
          <br/>
          <p>Providing AI-powered learning solutions to maximize learning outcomes for elementary to high school students.</p>
        </div>
        <div className="timeline-item">
          <h4>● 2026 ─────</h4>
          <h5>Expanding Global Korean<br/>Language Learning Service</h5>
          <br/>
          <p>Supporting global users in learning and understanding Korean with a specialized service, promoting the globalization of the Korean language.</p>
        </div>
        <div className="timeline-item">
          <h4>● 2027 ─────</h4>
          <h5>Launch of the First Version of<br/>a Personalized Assistant</h5>
          <br/>
          <p>Introducing the initial version of an AI assistant that aids memory and daily life, catering to users of all ages.</p>
        </div>
        <div className="timeline-item">
          <h4>● 2028 ─────</h4>
          <h5>Advanced AI Personal<br/>Assistant</h5>
          <br/>
          <p>Evolving into a highly developed AI assistant that supports a convenient life by managing all knowledge and memories in daily life.</p>
        </div>
      </div>
    </section>
  );
};

export default PathToFuture;
// src/components/Header.js
import React from 'react';
import '../styles/Header.css';

const Header = () => {
    return (
        <header className="header">
            <div className="header-left">
                <div className="subtitle">The</div>
                <h1>GPC | Great Potential Crew</h1>
            </div>
            <div className="header-right">
                <nav>
                    <ul>
                        <li onClick={() => handleScroll('vision')}>Vision</li>
                        <li onClick={() => handleScroll('services')}>Services</li>
                        <li onClick={() => handleScroll('team')}>Team</li>
                        <li onClick={() => handleScroll('path-to-future')}>Path to Future</li>
                        <li onClick={() => handleScroll('join-us')}>Join Us</li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

export default Header;

import React from 'react';
import '../styles/JoinUs.css';

const JoinUs = () => {
  return (
    <section id="join-us" className="join-us-section">
      <div className="join-us-container">
        <div className="join-us-left">
          <h2>Join us in shaping a better future</h2>
        </div>
        <div className="join-us-right">
          <p>With knowledge and learning woven into everyday life, G.P.C is here to<br/> help create a better world. Join us on this journey of growth and<br/>transformation.</p>
        </div>
      </div>
    </section>
  );
};

export default JoinUs;